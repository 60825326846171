<template>
    <td valign="middle">
        <div class="master-input-group">
            <div class="input-group input-group--amount">
                <input
                    v-model="quantity"
                    @input="updateBasket"
                    type="number"
                    class="form-control"
                    step="1"
                    tabindex="3"
                >

                <select
                    class="form-select form-select--measurement mb-0"
                    tabindex="3"
                    @change="changeMultiplier"
                    :value="selectedUnit"
                >
                    <option
                        v-for="(unit, key) in product.units"
                        :value="key"
                    >
                        {{ unit.unit }}
                    </option>
                </select>
            </div>

            <div class="input-group input-group--discount">
                <span class="input-group-text">€</span>

                <input
                    :value="priceToShow"
                    @blur="changePrice"
                    type="number"
                    class="form-control"
                    :class="{ 'form-control--changed' : defaultPrice !== unitPrice}"
                    style="width: 85px;"
                    tabindex="3"
                >

                <span class="input-group-text">-</span>

                <input
                    v-model="discount"
                    @change="updateBasket"
                    type="number"
                    step="1"
                    max="100"
                    class="form-control"
                    style="width: 50px;"
                    tabindex="3"
                >

                <span class="input-group-text">%</span>
            </div>
        </div>
    </td>
</template>

<script>
import {debounce} from 'lodash';

export default {
    props:{
        userDiscount: {
            default: null,
        },
        week:{
            required: true,
            type: Number,
        },
        product:{
            required: true,
        },
        basket:{
            required: true,
            default(){
                return {
                    quantity: null,
                    custom_price: null,
                    custom_promo_perc: null
                }
            }
        },
    },
    data(){
        return {
            requestsQueue: [],
            processingRequest: false,

            defaultPrice: this.product.price,
            minQuantity: (this.product.min_eenh > 0 ? this.product.min_eenh : 1),

            unitPrice: 0,
            discount: null,
            quantity: null,
            unitPriceMultiplier: 1,
            selectedUnit: null,
        }
    },
    computed:{
        priceToShow(){
            return (this.unitPrice * this.unitPriceMultiplier).toFixed(2);
        },
    },
    methods:{
        fillFields(){
            this.unitPrice = this.basket.custom_price ?? this.defaultPrice;
            this.selectedUnit = Object.keys(this.product.units)[0];
            this.unitPriceMultiplier = this.minQuantity*this.product.units[this.selectedUnit].quantity;

            if( !this.basket.quantity ) return;

            this.discount = this.basket.custom_promo_perc;
            let basketQuantity = this.basket.quantity;

            const units = this.product.units;
            const reversedUnitKeys = Object.keys(units).reverse();
            reversedUnitKeys.every(unitKey => {
                const unit = units[unitKey];
                if( basketQuantity%unit.quantity === 0 )
                {
                    this.unitPriceMultiplier = this.minQuantity*unit.quantity;
                    this.selectedUnit = unitKey;
                    this.quantity = basketQuantity/unit.quantity;
                    return false;
                }
                return true;
            });


        },
        changeMultiplier(event){
            const value = event.target.value
            this.unitPriceMultiplier = this.minQuantity* this.product.units[value].quantity;
            this.selectedUnit = value;
            this.updateBasket();
        },
        changePrice(event){
            const value = event.target.value;
            if( !value ){
                this.unitPrice = this.defaultPrice;
                this.discount = null;
                this.updateBasket();
                return;
            }
            this.unitPrice = value/this.unitPriceMultiplier;
            if( !this.discount && this.userDiscount ){
                this.discount = this.userDiscount;
            }
            this.updateBasket();
        },
        updateBasket: debounce(function(){
            this.addToQueue();
        }, 300),
        addToQueue() {
            const quantityInUnits = parseInt(this.quantity) * this.unitPriceMultiplier / this.minQuantity;
            const params = {
                product_kode: this.product.kode,
                list: 'week' + this.week,
                quantity: quantityInUnits,
                custom_price: (this.defaultPrice !== this.unitPrice) ? this.unitPrice : null,
                custom_promo_perc: this.discount,
            };

            // Clear the queue before adding a new request
            // Because other request will be outdated
            this.requestsQueue = [];

            this.requestsQueue.push(params);

            // Only start processing if not currently processing a request
            if (!this.processingRequest) {
                this.processQueue();
            }
        },
        async processQueue() {
            if (this.requestsQueue.length === 0 || this.processingRequest) return;

            this.processingRequest = true;
            const params = this.requestsQueue.shift();
            await this.pushToServer(params);
        },
        async pushToServer(params){
            // flare.reportMessage('start sending '+ params.product_kode, {'params': params} );
            axios
                .post('/representatives/api/basket', params)
                .then((response) => {
                    this.$emit('basketUpdated');
                    // flare.reportMessage('received OK response ' + params.product_kode, {'params': params, 'response': response.data} );
                }).catch((error) => {
                    // flare.report(error);
                }).finally(() => {
                    // Reset the processingRequest flag when the request is completed
                    this.processingRequest = false;
                    // Process the next request in the queue
                    this.processQueue();
                });
        }
    },
    mounted(){
        this.fillFields();
    },
}
</script>
