<template>
    <tr :class="{ 'tr--success' : basketIsUpdated }">
        <td valign="middle" class="td--first">
            <div class="product-combo">
                <div v-if="images" class="product-img">
                    <img
                        :src="product.image ?? '/img/logo.png'"
                        alt=""
                    >
                </div>

                <div class="product__desc p-0">
                    <div class="labels">
                        <div
                            v-if="product.labels.promo && !product.promoproc"
                            class="label"
                        >
                            PROMO
                        </div>
                        <div v-if="product.labels.promo && product.promoproc"
                            class="label"
                        >
                            {{ product.promoproc.toFixed(0) }}%
                        </div>
                        <div
                            v-if="!product.labels.in_stock"
                            class="label label--warning"
                        >
                            <strong>Let op:</strong> Op bestelling
                        </div>
                        <div
                            v-if="product.labels.eindereeks"
                            class="label label--warning"
                        >
                            Uit gamma
                        </div>
                        <div
                            v-if="product.labels.nietbeschikbaar"
                            class="label label--warning"
                        >
                            Tijdelijk niet beschikbaar
                        </div>
                    </div>


                    <h3 class="title">
                        <a :href="'/nl/producten/'+product.seo_mod_rewrite">{{ product.omschr}}</a>
                    </h3>

                    <div class="previous" v-if="product.recent.recent_product">
                        <div class="previous__date">{{ product.recent.datum }}</div>
                        <div class="previous__amount">{{ product.recent.totaal_aantal }}
                            {{ product.eenheid }}</div>
                    </div>

                    <span class="small">
                        {{  product.kode }}
                        <template v-if="product.merk">
                        | {{ product.merk }}
                        </template>
                    </span>
                </div>
            </div>
        </td>



        <template v-if="product.labels.eindereeks || product.labels.nietbeschikbaar">
            <td valign="middle" colspan="2">
                <div
                    v-if="product.vervangnummer_1"
                    class="text-end"
                >
                    <span class="mr-3">Vervangend product</span>
                    <button
                        type="button"
                        @click="showAlternativeProduct(product.vervangnummer_1)"
                        class="btn btn-primary"
                    >{{ product.alternative_product }}</button>
                </div>
            </td>
        </template>
        <template v-else>
            <product-order
                :week="1"
                :product="product"
                :basket="basket.week1"
                :user-discount="userDiscount"
                @basketUpdated="basketUpdated"
                key="week1"
            ></product-order>
            <product-order
                :week="2"
                :product="product"
                :basket="basket.week2"
                :user-discount="userDiscount"
                @basketUpdated="basketUpdated"
                key="week2"
            ></product-order>
        </template>

    </tr><!--/ tr -->
</template>

<script>

import ProductOrder
    from "./productOrder";

export default {
    components: {ProductOrder},
    props: {
        images: {
            required: true,
            type: Boolean,
        },
        userDiscount: {
            default: null,
        },
        basket: {
            required: true,
            default() {
                return{
                    'week1': {
                        quantity: null,
                        custom_price: null,
                        custom_promo_perc: null
                    },
                    'week2':{
                        quantity: null,
                        custom_price: null,
                        custom_promo_perc: null
                    },
                }

            }
        },
        product: {
            required: true,
        }
    },
    data(){
        return {
            basketIsUpdated: false,
        }
    },
    methods:{
        showAlternativeProduct(kode){
            this.$emit('showAlternativeProduct', kode);
        },
        basketUpdated(){
            this.$emit('basketUpdated');
            this.basketIsUpdated = true;
            setTimeout(()=>{
                this.basketIsUpdated = false;
            }, 500);
        }
    }
}
</script>
