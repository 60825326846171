var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"mb-3 pb-3"},[_c('h4',[_vm._v("Verlof")]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-striped"},[_vm._m(0),_vm._v(" "),_c('tbody',[_vm._l((_vm.holidays),function(holiday){return (!_vm.loading)?_c('tr',{key:holiday.id},[_c('td',{staticClass:"w-50"},[_vm._v(_vm._s(holiday.date_start))]),_vm._v(" "),_c('td',{staticClass:"w-50"},[_vm._v(_vm._s(holiday.date_end))]),_vm._v(" "),_c('td',{staticClass:"text-end"},[_c('button',{staticClass:"btn btn-link p-0 fw-bold",staticStyle:{"color":"red"},attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteHoliday(holiday.id)}}},[_vm._v("x")])])]):_vm._e()}),_vm._v(" "),(!_vm.loading && _vm.holidays.length === 0)?_c('tr',[_c('td',{staticClass:"fst-italic text-center",attrs:{"colspan":"3"}},[_vm._v("\n                                    -- Geen verlof ingegeven --\n                                ")])]):_vm._e()],2)])])])]),_vm._v(" "),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Van")]),_vm._v(" "),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                            weekNumbers: true,
                            minDate: 'today',
                            locale: 'nl',
                        },"id":"date_start","name":"date_start","required":""},model:{value:(_vm.newHoliday.dateStart),callback:function ($$v) {_vm.$set(_vm.newHoliday, "dateStart", $$v)},expression:"newHoliday.dateStart"}})],1)]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group mb-3"},[_c('h4',{staticClass:"mb-2"},[_vm._v("Tot")]),_vm._v(" "),_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{
                            weekNumbers: true,
                            minDate: _vm.newHoliday.dateStart ? _vm.newHoliday.dateStart : 'today',
                            locale: 'nl',
                        },"id":"date_start","name":"date_start","required":""},model:{value:(_vm.newHoliday.dateEnd),callback:function ($$v) {_vm.$set(_vm.newHoliday, "dateEnd", $$v)},expression:"newHoliday.dateEnd"}})],1)]),_vm._v(" "),(_vm.showValidationMessage)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"alert alert-danger",attrs:{"role":"alert"}},[_vm._v("\n                    Beide datums zijn verplicht.\n                ")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-12 d-flex align-items-center gap-3"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":_vm.validateAndSubmit}},[_vm._v("Verlof toevoegen")]),_vm._v(" "),(_vm.saveSuccess)?_c('div',{staticClass:"message-success"},[_vm._v("Opgeslagen")]):_vm._e(),_vm._v(" "),(_vm.saveFailed)?_c('div',{staticClass:"message-failed"},[_vm._v("Opslaan mislukt")]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("Van")]),_vm._v(" "),_c('th',{attrs:{"scope":"col"}},[_vm._v("Tot")]),_vm._v(" "),_c('th',{attrs:{"scope":"col"}})])])
}]

export { render, staticRenderFns }