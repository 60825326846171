import { flare } from "@flareapp/flare-client";

// Only enable Flare in production, we don't want to waste your quota while you're developing:
if (process.env.NODE_ENV === 'production') {
    flare.light();
}

// Set site info
// var siteinfo = document.getElementsByName("siteinfo")[0];
// var site_id = siteinfo.getAttribute("data-siteid");
// var lang = siteinfo.getAttribute("data-sitelang");

require('./boostrap.js');

let _ = require('lodash');
window.Vue = require('vue').default;

Vue.component('representative-products', require('./components/representatives/products.vue').default);
Vue.component('business-days', require('./components/account/BusinessDaysComponent.vue').default);
Vue.component('holidays', require('./components/account/HolidaysComponent.vue').default);

import VueFlatPickr from 'vue-flatpickr-component';
import { Dutch } from 'flatpickr/dist/l10n/nl.js'

import 'flatpickr/dist/flatpickr.css';
Vue.component('flat-pickr',VueFlatPickr);


if (document.getElementById('app')) {
    const app = new Vue({
        el: '#app',
    });
}

jQuery(function () {
	init_number_buttons();
	init_show_password_buttons();
	init_search();
	SaveOrderFormValuesToLocalStorageWhenChanged();

	// Textfit product badges
	// textFit(document.getElementsByClassName('product__badge'), {
	// 	detectMultiLine: true,
	// 	alignHoriz: true,
	// 	alignVert: true,
	// 	alignVertWithFlexbox: false,
	// 	minFontSize: 8,
	// 	maxFontSize: 18,
	// });

	// DataTables
	// $('.table--favourites').DataTable({
	// 	paging: false,
	// 	info: false,
	// 	order: [
	// 		[1]
	// 	],
	// 	columnDefs: [{
	// 		'targets': [0, 2, 4, 5],
	// 		'orderable': false,
	// 	}],
	// 	//responsive: true
	// });
	//
	// $('.table--invoices').DataTable({
	// 	paging: false,
	// 	info: false,
	// });

	$('#go-up').click(function() {
		$('body,html').animate({
			scrollTop: 0
		}, 300);
	});

	$('#go-down').click(function() {
		$('body,html').animate({
			scrollTop: $(document).height(),
		}, 300);
	});

	$("form").submit(function () {
		// prevent duplicate form submissions
		$(this).find(":submit").attr('disabled', 'disabled');
	});

	/*
	 *	RICH-CONTENT
	 */

	// Lightbox
	$('.lightbox').fancybox({
		buttons: ['close']
	});
	$('.lightbox-media').fancybox({
		overlayOpacity: 0.8,
		overlayColor: 'black',
		padding: 0,
		titleShow: true,
		helpers: {
			media: {}
		}
	});

	// Youtube/Vimeo thumbs
	$(".rc-video__thumb").not('.lightbox-media').click(startVideo);
	$(".rc-video__overlay").not('.lightbox-media').click(startVideo);

	function startVideo(e) {
		var block = $(e.currentTarget);
		var iframe = $('iframe', block.parent());
		var src = iframe.attr('data-src');

		if (src.indexOf('autoplay=0') > -1) {
			iframe.attr('src', src.replace('autoplay=0', 'autoplay=1'));
		} else {
			if (src.indexOf('?') > -1) {
				iframe.attr('src', src + '&autoplay=1');
			} else {
				iframe.attr('src', src + '?autoplay=1');
			}
		}

		$('.rc-video__overlay', block.parent()).fadeOut(150);
		$('.rc-video__thumb', block.parent()).fadeOut(150);
	}

	// Contentblocks slider
	$('.rc-article-slider').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// Quote slider
	$('.rc-quote-slider__slick').slick({
		adaptiveHeight: true,
		prevArrow: '<button type="button" class="slick-prev"><i class="icon-angle-left"></i></button>',
		nextArrow: '<button type="button" class="slick-next"><i class="icon-angle-right"></i></button>',
	});

	// File upload
	// bsCustomFileInput.init();
});

$(window).on('load', function ()
{
	// Image slider (options in data-slick)
	$('.rc-image-slider__slick').slick();
});

function init_search(){
	$('#search_recent_products').off('keyup search', search_recent_products);
	$('#search_recent_products').on('keyup search ', search_recent_products);
	$('#search_representative_products').off('keyup search', search_representative_products);
	$('#search_representative_products').on('keyup search', search_representative_products);
}

var recent_products_timeout = null;
function search_recent_products(e){
	e.preventDefault();

	let loader = $('.itemslist[data-modulename="recent-products"]').attr('data-loader');
	if (loader) {
		let html = decodeURI(loader);
		$('.itemslist[data-modulename="recent-products"]').html(html);
	}
	$('.itemslist[data-modulename="recent-products"]').attr('data-search', e.currentTarget.value);

	clearTimeout(recent_products_timeout);

	recent_products_timeout = setTimeout(() => {
		fetch_itemslist(1, () => {
			if ($('.itemslist[data-modulename="recent-products"]').find('[data-list]').length > 0) {
				fetch_list($('.itemslist[data-modulename="recent-products"]').find('[data-list]'));
			}
		});
	}, 300);

	return false;
}

var representative_products_timeout = null;
function search_representative_products(e) {
	e.preventDefault();

	let loader = $('.itemslist[data-modulename="representative-products"]').attr('data-loader');
	if (loader) {
		let html = decodeURI(loader);
		$('.itemslist[data-modulename="representative-products"]').html(html);
	}
	$('.itemslist[data-modulename="representative-products"]').attr('data-search', e.currentTarget.value);

	clearTimeout(representative_products_timeout);

	representative_products_timeout = setTimeout(() => {
		fetch_itemslist(1, () => {
			if ($('.itemslist[data-modulename="representative-products"]').find('[data-list]').length > 0) {
				fetch_list($('.itemslist[data-modulename="representative-products"]').find('[data-list]'));
			}
		});
	}, 300);

	return false;
}

function init_number_buttons(){
	$('.input-group--amount button:first-child').off('click', subtractNumber);
	$('.input-group--amount button:first-child').on('click', subtractNumber);
	$('.input-group--amount input + button').off('click', addNumber);
	$('.input-group--amount input + button').on('click', addNumber);
}

function init_show_password_buttons(){
	$('[data-action="show-password"]').off('click', toggleShowPassword);
	$('[data-action="show-password"]').on('click', toggleShowPassword);
}

function toggleShowPassword(e){
	let $btn = $(e.currentTarget);
	let $input = $($btn.attr('data-for'));

	if($btn.hasClass('active')){
		$btn.removeClass('active');
		$btn.find('.fa-eye-slash').removeClass('fa-eye-slash').addClass('fa-eye');
		$input.attr('type', 'password');
	}else{
		$btn.addClass('active');
		$btn.find('.fa-eye').removeClass('fa-eye').addClass('fa-eye-slash');
		$input.attr('type', 'text');
	}
}

function subtractNumber(e){
	let amount = -1;
	updateNumber(e, amount);
}

function addNumber(e) {
	let amount = 1;
	updateNumber(e, amount);
}

function updateNumber(e, amount){
	let button = e.currentTarget;
	let $input = $('input[type="number"]', $(button).parent());
	let step = parseInt($input.attr('step'));
	let user = $input.attr('data-user');
	if(!step || step < 1){
		step = 1;
	}

	amount = amount * step;

	let val = parseInt($input.val());
	val += amount;

	if(val < 1 && user !== "representative"){
		val = 1;
	}

	$input.val(val);
	$input.trigger('change');

}


// CAPTCHA
var captcha_validated = false;

function onSubmitreCAPTCHA(response)
{
	captcha_validated = true;
	$('.rc-form form').submit();
}

function validateForm(event)
{
	event.preventDefault();
	var form = this;

	var validator = $(form).data('bs.validator');
	validator.validate();

	if (captcha_validated && !validator.hasErrors()) {
		form.submit();
	} else if(!captcha_validated) {
		grecaptcha.reset();
		grecaptcha.execute();
	}
}

function SaveOrderFormValuesToLocalStorageWhenChanged(){
	// Set userId to value of attribute user-id from form
	var userId = $('#represenatatives-order-form').attr('user-id');

	let weeks = [
		'week1',
		'week2',
	];
	let radioFieldNames = [
		'delivery_day_in_stock',
		'delivery_day_out_of_stock',
	];
	let inputFieldNames = [
		'other_day_in_stock',
		'other_day_out_of_stock',
		'po_number',
	];
	let checkboxFieldNames = [
		'send_confirmation',
	];
	let textareaFieldNames = [
		'remarks',
		'delivery_info',
	];

	// loop through weeks
	weeks.forEach(week => {
		// loop through radio fields
		radioFieldNames.forEach(radioFieldName => {
			$(`input[name="orders[${week}][${radioFieldName}]"]`).on('change', function(){
				let value = $(this).val();
				let key = `${userId}_${week}_${radioFieldName}`;
				localStorage.setItem(key, value);
			});
		});


		// loop through input fields
		inputFieldNames.forEach(inputFieldName => {
			$(`input[name="orders[${week}][${inputFieldName}]"]`).on('keyup change', function(){
				let value = $(this).val();
				let key = `${userId}_${week}_${inputFieldName}`;
				localStorage.setItem(key, value);
			});
		});

		// loop through checkbox fields
		checkboxFieldNames.forEach(checkboxFieldName => {
			$(`input[name="orders[${week}][${checkboxFieldName}]"]`).on('change', function(){
				// if checked set value to 1, else clear localstorage
				let value = $(this).is(':checked') ? 1 : '';
				let key = `${userId}_${week}_${checkboxFieldName}`;
				localStorage.setItem(key, value);
			});
		});

		// loop through textarea fields and save to local storage
		textareaFieldNames.forEach(textareaFieldName => {
			$(`textarea[name="orders[${week}][${textareaFieldName}]"]`).on('keyup change', function(){
				let value = $(this).val();
				let key = `${userId}_${week}_${textareaFieldName}`;
				localStorage.setItem(key, value);
			});
		});

		// Set the localstorage values on pageload
		radioFieldNames.forEach(radioFieldName => {
			let key = `${userId}_${week}_${radioFieldName}`;
			let value = localStorage.getItem(key);
			if(value){
				$(`input[name="orders[${week}][${radioFieldName}]"][value="${value}"]`).prop('checked', true);
			}
		});

		inputFieldNames.forEach(inputFieldName => {
			let key = `${userId}_${week}_${inputFieldName}`;
			let value = localStorage.getItem(key);
			if(value){
				$(`input[name="orders[${week}][${inputFieldName}]"]`).val(value);
			}
		});

		checkboxFieldNames.forEach(checkboxFieldName => {
			let key = `${userId}_${week}_${checkboxFieldName}`;
			let value = localStorage.getItem(key);
			if(value){
				$(`input[name="orders[${week}][${checkboxFieldName}]"]`).prop('checked', true);
			}
		});

		textareaFieldNames.forEach(textareaFieldName => {
			let key = `${userId}_${week}_${textareaFieldName}`;
			let value = localStorage.getItem(key);
			if(value){
				$(`textarea[name="orders[${week}][${textareaFieldName}]"]`).val(value);
			}
		});
	});

	// When the form with id represenatatives-order-form submits, clear all localstorage values
	$('#represenatatives-order-form').on('submit', function(){
		localStorage.clear();
	});

	// When logout button is clicked, clear all localstorage values
	$('#logout').on('click', function(){
		localStorage.clear();
	});
}
