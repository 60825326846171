/*
 *  Imports & dependencies
 */

window.$ = window.jQuery = require('jquery');

// Popper (for Bootstrap tooltips)
import Popper from 'popper.js';
window.Popper = Popper;

// Fancybox 3 (requires jQuery)
require('../../plugins/fancybox3/dist/jquery.fancybox.js');

// Sliders (requires jQuery)
require('../../plugins/slick-1.8.1/slick/slick.js');

require('../../plugins/select2/js/select2.min.js');
require('../../plugins/select2/js/i18n/nl.js');

import Swal from 'sweetalert2';
window.Swal = Swal;

// Bootstrap
window.bootstrap = require('bootstrap');


/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Next we will register the CSRF Token as a common header with Axios so that
 * all outgoing HTTP requests automatically have it attached. This is just
 * a simple convenience so we don't have to attach every token manually.
 */

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

//require('./_defer_images.js');
require('./webshop.js');
require('./forms.js');
require('./components/itemslist.js');
require('./components/list.js');
