$(function () {

    if($('[data-list]').length > 0){
        fetch_list($('[data-list]'));
    }

    $('[data-list-filter]').each((index, el) => {
        init_list_filter($(el));
    });

    init_list_components();

});

window.init_list_components = function(){
    if ($('[data-list-count]').length > 0) {
        init_list_counts();
    }
}

function init_list_counts(){
    lists = [];
    $('[data-list-count]').each((index, el) => {
        lists.push($(el).attr('data-list-count'));
    });

    for (let i = 0; i < lists.length; i++) {
        const list = lists[i];
        $.post('/webshop/' + site_id + '/' + lang + '/list/count', {
            list: list
        }, result => {
            $('[data-list-count="' + result.list + '"').html(result.count);
        });
    }
}

function format_serialize_data(data){
    let arr_data = {};

    for (let i = 0; i < data.length; i++) {
        const element = data[i];

        if(element.name.includes('[]')){
            let name = element.name.substr(0, element.name.length-2);
            if (!arr_data[name]){
                arr_data[name] = [];
            }

            arr_data[name].push(element.value);
        }else{
            arr_data[element.name] = element.value;
        }

    }

    return arr_data;
}

function init_list_filter($el){
    let modulename = $el.attr('data-list-filter');

    $('.sublist').each(function(){
        let parent_checkbox = $(this).attr('data-parent');

        if (!$('#'+parent_checkbox).is(':checked')) {
            $(this).hide();
        }
    });

    // Children
    $el.find('[id^="filter_category_"]').on('change', (e) => {
        let parent = false;
        parent = $(e.currentTarget).attr('data-parent-for');

        if (parent && parent != '') {
            if ($(e.currentTarget).is(':checked')) {
                $('#'+parent).slideDown();

                $('input[id^="filter_category_"][type="checkbox"][data-parent-for!=' + parent + ']').prop('checked', false);

                // Uncheck other main categories & slide up
                $('.sublist[data-parent!="' + e.currentTarget.id + '"] input[type="checkbox"]').prop('checked', false);
                $('.sublist:not(#'+parent+')').slideUp();
            } else {
                //$('#'+parent).slideUp();
            }
        }

        if(e.currentTarget.checked){
            $('.sublist[data-parent="' + e.currentTarget.id + '"] input[type="checkbox"]').prop('checked', true);
        }else{
            $('.sublist[data-parent="' + e.currentTarget.id + '"] input[type="checkbox"]').prop('checked', false);
        }
    });

    $el.find('select, input').on('change', () => {

        let data = $el.serializeArray();
        data = format_serialize_data(data);

        $list = $('[data-list="' + modulename + '"]');

        // Start from page 1
        $list.attr('data-page', 1);

        set_url_params(data);

        /*if(!data.categories){
            window.document.location.reload();
            return false;
        }*/

        $list.attr('data-params', JSON.stringify(data));
        fetch_list($list);

        return false;
    });

    $('select.search-filter').select2({
        //theme: 'bootstrap4',
        language: "nl",
    });
}

function set_url_params(data){
    if (window.history.pushState) {
        let newUrl = window.location.href.split('?')[0];
        newUrl += '?' + $.param(data);
        window.history.pushState({}, null, newUrl);
    } else {
        window.location.search = $.param(data);
    }
}

function scroll_to_element(element){
    if (document.getElementById(element)){
        window.scroll(0, findPos(document.getElementById(element)));
    }
}

//Finds y value of given object
function findPos(obj) {
    var curtop = 0;
    if (obj.offsetParent) {
        do {
            curtop += obj.offsetTop;
        } while (obj = obj.offsetParent);
        return [curtop];
    }
}

function start_loading($el){
    $('> .list', $el).html('');
    $('#loader', $el).show();
}

function stop_loading($el) {
    $('#loader', $el).hide();
}

function fetch_list($el){

    let modulename = $el.attr('data-list');

    let params = $el.attr('data-params');
    if(params){
        params = JSON.parse(params);
    }else{
        params = {};
    }


    let pagenr = $el.attr('data-page');
    if(pagenr){
        params.pagenr = $el.attr('data-page');
    }

    set_url_params(params);
    scroll_to_element(modulename);
    start_loading($el);

    $.get('/ajax/list/' + site_id + '/' + lang + '/' + modulename, params, function (data) {
        let $html = $(data);
        $html.find('a.page-link').on('click', (e) => {
            e.preventDefault();

            let pagenr = $(e.target).attr('href');
            //let pagenr = page.replace('#page', '');
            $el.attr('data-page', pagenr);
            fetch_list($el);

            return false;
        });


        $('> .list', $el).html($html);

        init_webshop_actions();
        init_webshop_components();
    }).always(function(){
        stop_loading($el);
    });
}
