$(document).ready(function(){
	var pagenr = 1;
	if (window.location.hash) {
		var page = window.location.hash.substr(1);
		pagenr = page.replace('page', '');
	}
	fetch_itemslist(pagenr);
});

window.fetch_itemslist = function(pagenr, callback){
	$('.itemslist').each(function(index, el){
		var options = {};
		if(get_attr_value(el, 'data-itemsperpage')){
			options.itemsperpage = get_attr_value(el, 'data-itemsperpage');
		}
		if(get_attr_value(el, 'data-modulename')){
			options.modulename = get_attr_value(el, 'data-modulename');
		}
		if(get_attr_value(el, 'data-layout')){
			options.layout = get_attr_value(el, 'data-layout');
		}
		if(get_attr_value(el, 'data-breadcrumbs')){
			options.breadcrumbs = get_attr_value(el, 'data-breadcrumbs');
		}
		if(get_attr_value(el, 'data-linked')){
			options.linked = get_attr_value(el, 'data-linked');
		}
		if(get_attr_value(el, 'data-parentid')){
			options.parentid = get_attr_value(el, 'data-parentid');
		}
		if(get_attr_value(el, 'data-search')){
			options.search = get_attr_value(el, 'data-search');
		}
		if(get_attr_value(el, 'data-order')){
			options.order = get_attr_value(el, 'data-order');
		}
		if (get_attr_value(el, 'data-loader')) {
			options.loader = get_attr_value(el, 'data-loader');
		}else{
			$(el).attr('data-loader', encodeURI($(el).find('.loader').parent().html()));
		}

		options.pagenr = pagenr;
		get_items(options, callback);
	});
}

function get_attr_value(el, attr_name){
	return $(el).attr(attr_name);
}

function get_items(options, callback){

	$.get('/ajax/'+site_id+'/'+lang+'/'+options.modulename, options, function(data){
		$('.itemslist').html(data);

		$('.pager-ajax a.page-link').click(function(){
			var page = $(this).attr('href');
			var pagenr = page.replace('#page', '');

			window.scrollTo(0, 0);

			let loader = $('.itemslist[data-modulename="'+options.modulename+'"]').attr('data-loader');
			if (loader) {
				let html = decodeURI(loader);
				$('.itemslist[data-modulename="'+options.modulename+'"]').html(html);
			}

			fetch_itemslist(pagenr);

			return false;
		});

		init_webshop_actions();

		if(callback){
			callback();
		}
	});
}
