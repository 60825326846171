<template>
    <div>
        <div class="mb-3 pb-3">
            <h4>Openingsdagen</h4>
            <div v-for="(day, key) in days" :key="key" class="form-check form-switch">
                <label :for="key" class="form-check-label">{{ day }}</label>
                <input class="form-check-input" type="checkbox" :id="key" v-model="closingDays[key]">
            </div>

            <div class="d-flex align-items-center gap-3 mt-3">
                <button
                    @click="saveDays"
                    class="btn btn-primary"
                >Openingsdagen opslaan</button>
                <div class="message-success" v-if="saveSuccess">Opgeslagen</div>
                <div class="message-failed" v-if="saveFailed">Opslaan mislukt</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        businessDays: {
            required: true,
        }
    },
    data(){
        return {
            saveSuccess: false,
            saveFailed: false,
            days: {
                monday: 'Maandag',
                tuesday: 'Dinsdag',
                wednesday: 'Woensdag',
                thursday: 'Donderdag',
                friday: 'Vrijdag',
                saturday: 'Zaterdag',
                sunday: 'Zondag',
            },
            closingDays: {
                monday: true,
                tuesday: true,
                wednesday: true,
                thursday: true,
                friday: true,
                saturday: true,
                sunday: true,
            },
        }
    },
    mounted() {
        if(this.businessDays === null) return;

        for (const [key, value] of Object.entries(this.businessDays)) {
            this.closingDays[key] = value;
        }
    },
    methods: {
        saveDays(){
            const url = '/account/business-days';
            axios.post(url, this.closingDays)
                .then(response => {
                    this.saveSuccess = true;
                    this.saveFailed = false;
                    setTimeout(() => {
                        this.saveSuccess = false;
                    }, 3000);
                })
                .catch(error => {
                    this.saveFailed = true;
                    this.saveSuccess = false;
                    setTimeout(() => {
                        this.saveFailed = false;
                    }, 3000);
                });
        }
    }
}
</script>

<style>
.form-switch .form-check-input {
    background-color: #ff4d4d; /* Red background when off */
    border: 1px solid #ff4d4d;
    transition: background-color 0.3s, border-color 0.3s;
}

.form-switch .form-check-input:checked {
    background-color: #4caf50; /* Green background when on */
    border-color: #4caf50;
}

.form-switch .form-check-input:focus {
    box-shadow: none;
}

.message-success{
    display: inline-block;
    margin-left: 10px;
    background: #4caf50;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 10px;
}
.message-failed{
    display: inline-block;
    margin-left: 10px;
    background: #ff4d4d;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 10px;
}
</style>
