<template>
    <div>
        <div class="mb-3 pb-3">
            <h4>Verlof</h4>
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-striped">
                            <thead>
                                <tr>
                                    <th scope="col">Van</th>
                                    <th scope="col">Tot</th>
                                    <th scope="col"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-if="!loading" v-for="holiday in holidays" :key="holiday.id">
                                    <td class="w-50">{{ holiday.date_start }}</td>
                                    <td class="w-50">{{ holiday.date_end }}</td>
                                    <td class="text-end">
                                        <button
                                            type="button"
                                            class="btn btn-link p-0 fw-bold"
                                            style="color: red;"
                                            @click="deleteHoliday(holiday.id)"
                                        >x</button>
                                    </td>
                                </tr>
                                <tr
                                    v-if="!loading && holidays.length === 0"
                                >
                                    <td
                                        class="fst-italic text-center"
                                        colspan="3">
                                        -- Geen verlof ingegeven --
                                    </td
                                ></tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group mb-3">
                        <h4 class="mb-2">Van</h4>
                        <flat-pickr
                            :config="{
                                weekNumbers: true,
                                minDate: 'today',
                                locale: 'nl',
                            }"
                            class="form-control"
                            id="date_start"
                            name="date_start"
                            v-model="newHoliday.dateStart"
                            required
                        />
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group mb-3">
                        <h4 class="mb-2">Tot</h4>
                        <flat-pickr
                            :config="{
                                weekNumbers: true,
                                minDate: newHoliday.dateStart ? newHoliday.dateStart : 'today',
                                locale: 'nl',
                            }"
                            class="form-control"
                            id="date_start"
                            name="date_start"
                            v-model="newHoliday.dateEnd"
                            required
                        />
                    </div>
                </div>
                <div class="col-12" v-if="showValidationMessage">
                    <div class="alert alert-danger" role="alert">
                        Beide datums zijn verplicht.
                    </div>
                </div>
                <div class="col-12 d-flex align-items-center gap-3">
                    <button
                        type="button"
                        @click="validateAndSubmit"
                        class="btn btn-primary"
                    >Verlof toevoegen</button>
                    <div class="message-success" v-if="saveSuccess">Opgeslagen</div>
                    <div class="message-failed" v-if="saveFailed">Opslaan mislukt</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            loading: true,
            showValidationMessage: false,
            saveSuccess: false,
            saveFailed: false,
            days: {},
            newHoliday: {
                dateStart: null,
                dateEnd: null,
            },
            holidays: [],
        }
    },
    created() {
        this.getHolidays();
    },
    methods: {
        getHolidays(){
            this.loading = true;
            const url = '/account/holidays';
            axios.get(url)
                .then(response => {
                    this.holidays = response.data;
                    this.loading = false;
                });
        },
        validateAndSubmit(){
            this.showValidationMessage = false;
            if(!this.newHoliday.dateStart || !this.newHoliday.dateEnd){
                this.showValidationMessage = true;
                return;
            }
            this.addHoliday();
        },
        addHoliday(){
            const url = '/account/holidays';
            axios.post(url, this.newHoliday)
                .then(response => {
                    this.saveSuccess = true;
                    this.saveFailed = false;
                    this.newHoliday = {
                        dateStart: null,
                        dateEnd: null,
                    };
                    this.getHolidays();
                    setTimeout(() => {
                        this.saveSuccess = false;
                    }, 3000);
                })
                .catch(error => {
                    this.saveFailed = true;
                    this.saveSuccess = false;
                    setTimeout(() => {
                        this.saveFailed = false;
                    }, 3000);
                });
        },
        deleteHoliday(id){
            const url = '/account/holidays/' + id;
            axios.delete(url)
                .then(response => {
                    this.getHolidays();
                });
        }
    }

}
</script>

<style>
.message-success{
    display: inline-block;
    margin-left: 10px;
    background: #4caf50;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 10px;
}
.message-failed{
    display: inline-block;
    margin-left: 10px;
    background: #ff4d4d;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 10px;
}
</style>
