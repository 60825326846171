<template>
    <div class="representatives-view" id="scrollArea">

        <nav class="navbar--main navbar navbar-expand-lg">
            <div class="container">
                <div class="row align-items-center flex-grow-1">
                    <div class="col-auto">
                        <a class="navbar-brand" href="/nl/dashboard-vertegenwoordiger">
                            <img src="/img/logo.png" class="img-fluid navbar-brand-logo" />
                            <strong>Orizon West</strong><span class="d-none d-sm-inline">&nbsp;|&nbsp;Klantenportaal</span>
                        </a>
                    </div>

                    <div class="col-auto ml-auto">
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
                            <i class="fa fa-bars" aria-hidden="true"></i>
                        </button>

                        <div class="collapse navbar-collapse ms-auto" id="navbar-collapse">
                            <ul class="navbar-nav ms-auto">
                                <li class="nav-item"><a href="/representatives/recent-products" class="nav-link">Recente bestellingen</a></li>
                                <li class="nav-item"><a href="/representatives/products" class="nav-link">Volledig gamma</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-auto">
                        <div class="search mb-0">
                            <form
                                @submit.prevent
                                action="search">
                                <div class="input-group">
                                    <input
                                        class="form-control"
                                        type="search"
                                        name="search"
                                        minlength="2"
                                        placeholder="Zoek uw product..."
                                        @change="searchChanged"
                                        @input="searchChanged"
                                        :value="search"
                                        tabindex="2"
                                    />
                                    <button class="btn btn-primary" type="button"><i class="fas fa-search"></i></button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div class="col-auto">
                        <a href="/nl/bestellen-vertegenwoordiger" class="btn btn-lg btn-secondary"><i class="fas fa-shopping-cart"></i><span class="badge ms-2 position-static">{{ basketAmount }}</span></a>
                    </div>
                </div><!--/ .row -->
            </div><!--/ container -->
        </nav>


        <div v-if="loading || loadingBasket" class="loader text-center mt-3">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
        </div>
        <div
            v-else
            class="container-fluid"
        >
            <div class="col-12">
                <table class="table table--power-user">
                    <tbody>
                    <template
                        v-for="(product, key) in productsToShow"
                    >
                        <tr v-if="showAllProducts(key)" key="allproducts">
                            <th colspan="4">
                                <div class="fst-italic pt-3" style="color:#feda00">
                                    Volledig gamma
                                </div>
                            </th>
                        </tr>
                        <tr v-if="groupToShow(key)" :key="key+product.groep">
                            <th colspan="4">{{ groupToShow(key) }}</th>
                        </tr>
                        <product
                            :product="product"
                            :basket="basket[product.kode]"
                            :user-discount="userDiscount"
                            :key="product.kode"
                            :images="images"
                            @showAlternativeProduct="showAlternativeProduct"
                            @basketUpdated="getBasket()"
                        />
                    </template>
                    </tbody>
                </table>
            </div>
        </div>


        <button class="go-up" id="go-up"><i class="fas fa-angle-up"></i></button>
        <button class="go-down" id="go-down"><i class="fas fa-angle-down"></i></button>

        <span id="trigger"></span>
    </div>
</template>

<script>
import Product
    from "./product";
export default {
    components: {Product},
    props: {
        userDiscount: {
            default: null,
        },
        recentProducts: {
            type: Boolean,
            default: false,
        },
        images: {
            type: Boolean,
            default: false,
        }
    },
    data(){
        return{
            loading: true,
            loadingBasket: true,
            observer: null,
            search: '',
            groups: {},
            products: [],
            basket: {},
            amountOfProductsToShow: 10,
        }
    },
    computed: {
        basketAmount(){
            let amount = 0;
            const basket = this.basket;
            for(const product in this.basket){
                amount += Object.keys(this.basket[product]).length;
            }
            return amount;
        },
        searchTerms(){
            if( !this.search ){
                return [];
            }
            return this.search.trim().toLowerCase().split(' ');
        },
        productsToShow() {
            let products =  [...this.products];
            const amountOfProductsToShow = this.amountOfProductsToShow;

            const fullSearchString = this.search.toLowerCase();
            const searchTerms = this.searchTerms;
            if( searchTerms.length ){

                products = products
                    .filter(product => {
                        const omschr = product.omschr ?? '';
                        const kode = product.kode ?? '';
                        const artikelnummer_leverancier = product.artikelnummer_leverancier ?? '';
                        const trefwoorden = product.trefwoorden ?? '';

                        product.matchValue = 0;

                        if( omschr.toLowerCase().includes(fullSearchString) ||
                            kode.toLowerCase().includes(fullSearchString) ||
                            artikelnummer_leverancier.toLowerCase().includes(fullSearchString) ||
                            trefwoorden.toLowerCase().includes(fullSearchString)
                        ) {
                            product.matchValue = 1000;
                            return true;
                        }


                        searchTerms.forEach(term => {
                            if( omschr.toLowerCase().includes(term) ||
                                kode.toLowerCase().includes(term) ||
                                artikelnummer_leverancier.toLowerCase().includes(term) ||
                                trefwoorden.toLowerCase().includes(term)
                            ){
                                product.matchValue++;
                            }
                        });
                        return (product.matchValue > 0);
                    });
            }

            return products
                .sort((a, b) => b.matchValue - a.matchValue)
                .sort((a,b) => b.recent.recent_product - a.recent.recent_product)
                .slice(0,amountOfProductsToShow);
        },
    },
    methods:{
        getBasket(){
            axios.get('/representatives/api/basket')
                .then((response) =>{
                    this.basket = response.data;
                    this.loadingBasket = false;
                });
        },
        getProductData(){
            axios.get('/representatives/api/products')
                .then((response) =>{
                    let products = response.data.products;
                    if( this.recentProducts ){
                        this.products = products.sort((a,b) => b.recent.recent_product - a.recent.recent_product);
                    }else{
                        this.products = products;
                    }
                    this.groups = response.data.groups;
                    this.loading = false;
                });
        },
        groupToShow(key){
            const currentProduct = this.productsToShow[key];
            const previousProduct = this.productsToShow[key-1];
            if( previousProduct && currentProduct.groep === previousProduct.groep ){
                return false;
            }

            if( !currentProduct.groep ) return 'Andere';

            return this.groups[currentProduct.groep].omschr ?? false;
        },
        showAllProducts(key){
            if( !this.recentProducts ){
                return false;
            }
            const currentProduct = this.productsToShow[key];
            const previousProduct = this.productsToShow[key-1];
            return (
                (previousProduct &&
                    currentProduct.recent.recent_product !== previousProduct.recent.recent_product) ||
                (!previousProduct && !currentProduct.recent.recent_product)
            );
        },
        initObserver(){
            let options = {
                rootMargin: '100px',
                threshold: .1
            };
            let callback = (entries, observer) => {
                entries.forEach(entry => {
                    if( entry.isIntersecting ){
                        this.amountOfProductsToShow += 10;
                    }
                });
            };
            let observer = new IntersectionObserver(callback, options);
            let target = document.querySelector('#trigger');
            observer.observe(target);
        },
        showAlternativeProduct(kode){
            this.search = kode;

        },
        searchChanged: _.debounce(function (event) {
            this.search = event.target.value;
        }, 300)
    },
    created() {
        this.getProductData();
        this.getBasket();
    },
    mounted() {
        this.initObserver();
    }
}
</script>

<style
    scoped>

</style>
