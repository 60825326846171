/*
*	Gebruiksaanwijzingen webshop
*
*	Toevoegen winkelmandje:
*	data-webshop-action="add-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal toe te voegen items]"

*	Verwijderen uit winkelmandje:
*	data-webshop-action="remove-item"
*	data-item-id="[itemid]"
*	data-item-amount="[aantal te verwijderen items]"
*
*	Winkelmandje weergeven:
*	data-webshop-component="basket-amount"
*	data-webshop-component="basket-total"
*
*	data-webshop-component="basket-dropdown"
*
*		data-webshop-component="basket-dropdown-list"
*		-> Onder dit element een element met klasse "template-row" en "display:none" geplaatst
*			Dit element wordt dan gebruikt als basis voor product
*
*			Placeholders voor template-row:
*			- [[product_title]]
*			- [[product_amount]]
*			- [[product_total_price]]
*			- [[product_price]]
*			- [[product_id]]
*			- [[product_image_url]]
*
*/
$(function () {
	init_webshop_actions();
	init_webshop_components();
});

window.init_webshop_actions = function() {
	$('[data-webshop-action="add-favourites"]').off('click', add_item_to_favourites);
	$('[data-webshop-action="add-favourites"]').on('click', add_item_to_favourites);
	$('[data-webshop-action="add-basket"]').off('click', add_item_to_basket);
	$('[data-webshop-action="add-basket"]').on('click', add_item_to_basket);
	$('[data-webshop-action="delete-basket"]').off('click', delete_item_from_basket);
	$('[data-webshop-action="delete-basket"]').on('click', delete_item_from_basket);
	$('[data-webshop-action="update-basket-item"]').off('change', update_item_basket);
	$('[data-webshop-action="update-basket-item"]').on('change', update_item_basket);
	$('[data-list-action="update-item"]').off('change', update_item);
	$('[data-list-action="update-item"]').on('change', update_item);
}

window.init_webshop_components = function(){
	init_favourite_buttons();
}

function init_favourite_buttons() {
	$.get('/webshop/' + site_id + '/' + lang + '/favourites', function (result) {
		let favourites = result;

		$('[data-webshop-action="add-favourites"] i.fas').removeClass('fas').addClass('far');
		for (let i = 0; i < favourites.length; i++) {
			const fav = favourites[i];
			$('[data-webshop-action="add-favourites"][data-itemid="' + fav.kode + '"] i').removeClass('far').addClass('fas');
		}
	});
}

function delete_item_from_basket(e){
	let itemid = e.currentTarget.getAttribute('data-itemid');
	let message = e.currentTarget.getAttribute('data-message');
	let list = e.currentTarget.getAttribute('data-webshop-list');

	if (!list){
		list = 'basket';
	}

	Swal.fire({
		text: message,
		showDenyButton: false,
		icon: 'warning',
		showCancelButton: true,
		cancelButtonColor: '#feda00',
		cancelButtonText: 'Annuleren',
		confirmButtonColor: '#d33',
		confirmButtonText: 'Verwijderen',
	}).then((result) => {
		/* Read more about isConfirmed, isDenied below */
		if (result.isConfirmed) {
			$.ajax({
				url: '/webshop/' + site_id + '/' + lang + '/delete',
				type: 'DELETE',
				data: {
					itemid: itemid,
					list: list
				},
				success: function(result){
					update_basket();
					$('[data-basket="' + result.itemid + '"]').fadeOut(() => {
						$(this).remove();
					});
				}
			});
		}
	})
}

function add_item_to_favourites(e){
	let itemid = e.currentTarget.getAttribute('data-itemid');
	add_item_to_list('favourites', itemid);
}

function add_item_to_basket(e){
	let itemid = e.currentTarget.getAttribute('data-itemid');
	let quantity = e.currentTarget.getAttribute('data-quantity');
	if(isNaN(quantity)){
		quantity = $(quantity).val();
	}

	let multiplier = e.currentTarget.getAttribute('data-multiplier');
	if(!multiplier){
		multiplier = 1;
	}else if (isNaN(multiplier)) {
		multiplier = $(multiplier).val();
	}

	quantity = quantity*multiplier;

	add_item_to_list('basket', itemid, quantity);
	show_product_toast(e.currentTarget.getAttribute('data-name'), quantity);
}

function show_product_toast(name, quantity){
	// Copy the toast template & show a notification
	let toast_container = document.querySelector('#toast-container');
	let toast_template = document.querySelector('#toast-product-added');

	let toast = toast_template.cloneNode(true);
	let added_toast_name = toast.querySelector('.toast-product-added-name');
	let added_toast_amount = toast.querySelector('.toast-product-added-amount');

	toast.classList.remove('d-none');
	toast.id = '#toast-product-added-' + Date.now().toString(36) + Math.random().toString(36).substr(2);
	added_toast_name.innerHTML = name;
	added_toast_amount.innerHTML = quantity;

	toast_container.append(toast);

	let bs_toast = new bootstrap.Toast(toast, {
		animation: true,
		autohide: true,
		delay: 5000
	});
	bs_toast.show();
}

function update_item(e){
	let itemid = e.currentTarget.getAttribute('data-itemid');
	let multiplier = e.currentTarget.value;

	let list = e.currentTarget.getAttribute('data-webshop-list');
	let selector = '[data-webshop-component="item-price"][data-itemid="' + itemid + '"]';
	if(list){
		selector += '[data-webshop-list="'+list+'"]';
	}

	let unit_price = parseFloat($(selector).attr('data-unit-price'));
	let price = (Math.round((unit_price * multiplier) * 100) / 100).toFixed(2);
	$(selector).html(price).val(price);

	$.post('/webshop/' + site_id + '/' + lang + '/list/reset-price', {
		itemid: itemid,
		list: list,
	}, function (result) {
		if (init_list_components) {
			init_list_components();
		}

		update_basket();
	});
}

var products_updated = [];
function update_item_basket(e) {
	let itemid = e.currentTarget.getAttribute('data-itemid');
	let field = e.currentTarget.getAttribute('data-field');
	let list = e.currentTarget.getAttribute('data-webshop-list');
	let multiplier = 1;
	let quantity = 1;

	if (!list){
		list = 'basket';
	}

	if(field == 'quantity'){
		multiplier = e.currentTarget.getAttribute('data-multiplier');
		if(isNaN(multiplier)){
			multiplier = $(multiplier).val();
		}
		quantity = e.currentTarget.value*multiplier;
	}else if(field == 'multiplier'){
		multiplier = e.currentTarget.value;
		quantity = e.currentTarget.getAttribute('data-quantity');
		if (isNaN(quantity)) {
			quantity = $(quantity).val();
		}
		quantity = quantity*multiplier;
		field = "quantity";
	}else if( field === 'custom_price' ){
		multiplier = e.currentTarget.getAttribute('data-multiplier');
		if(isNaN(multiplier)){
			multiplier = $(multiplier).val();
		}
		quantity = e.currentTarget.value/multiplier;
	}else{
		quantity = e.currentTarget.value;
	}

	$.post('/webshop/' + site_id + '/' + lang + '/list/update', {
		itemid: itemid,
		list: list,
		val: quantity,
		field: field,
	}, function (result) {
		if (init_list_components) {
			init_list_components();
		}

		if (!products_updated.includes(itemid+list) && e.currentTarget.getAttribute('data-product-toast')) {
			products_updated.push(itemid+list);
			show_product_toast(e.currentTarget.getAttribute('data-product-toast'), quantity);
		}
		update_basket();
		if( field === 'custom_price' ){
			let name = e.currentTarget.getAttribute('name')
			let percentageField = name.replace('custom_price','custom_promo_perc');

			let percentageFieldValue = $('[name="'+percentageField+'"]').val();
			let percentageFieldDiscount = $('[name="'+percentageField+'"]').data('user-line-discount');
			console.log( percentageFieldValue, percentageFieldDiscount);
			if( percentageFieldValue == 0 && percentageFieldDiscount > 0){
				$('[name="'+percentageField+'"]').val(percentageFieldDiscount).change();
			}
		}
	});
}

function update_basket() {

	let lists = [];
	$('[data-list-total]').each(function(i, el){
		if (!lists.includes($(el).attr('data-list-total'))){
			lists.push($(el).attr('data-list-total'));
		}
	});

	for (let i = 0; i < lists.length; i++) {
		const list = lists[i];
		$.get('/webshop/' + site_id + '/' + lang + '/basket?list='+list, function (result) {
			$('[data-list-vat="'+result.list+'"]').html(result.vat);
			if (parseFloat(result.empties) <= 0){
				$('[data-row-empties]').hide();
			}else{
				$('[data-row-empties]').show();
			}
			$('[data-list-empties="'+result.list+'"]').html(result.empties);
			$('[data-list-subtotal="' + result.list + '"]').html(result.subtotal);
			$('[data-list-total="' + result.list + '"]').html(result.total);

			for (let i = 0; i < result.items.length; i++) {
				const item = result.items[i];
				$('[data-list-product-total="' + result.list + '"][data-itemid="' + item.kode + '"]').html(item.total);
			}

		});
	}
}

function add_item_to_list(list, itemid, quantity){
	if (typeof quantity == 'undefined') {quantity = 1;}

	$.post('/webshop/' + site_id + '/' + lang + '/list/add', {
		itemid: itemid,
		list: list,
		quantity: quantity
	}, function (result) {

		if (init_list_components){
			init_list_components();
		}

		update_basket();

		if (result.list == 'favourites'){
			init_favourite_buttons();
			if(result.quantity != 1){
				$('[data-favourite="' + result.itemid + '"]').fadeOut(() => {
					$(this).remove();
				});
			}
		}
	});
}
